<script setup lang="ts">
import type { MappedOmit } from '~/types/common.type'
import type { NotificationThreshold } from '~/types/notification-threshold.type'

const model = defineModel({
  default: [],
  type: Array as PropType<MappedOmit<NotificationThreshold, 'id'>[]>,
})

function addNotificationThreshold() {
  model.value.push({
    value: 100,
    notifyAdmin: false,
    notifyProjectManager: true,
  })
}

function removeNotificationThreshold(index: number) {
  model.value.splice(index, 1)
}
</script>

<template>
  <div class="space-y-3.7">
    <NotificationThreshold
      v-for="(notificationThreshold, index) in model"
      :key="`threshold-${index}`"
      v-model:value="notificationThreshold.value"
      v-model:notify-admin="notificationThreshold.notifyAdmin"
      v-model:notify-project-manager="notificationThreshold.notifyProjectManager"
      @remove-notification-threshold="removeNotificationThreshold(index)"
      @add-notification-threshold="addNotificationThreshold"
    />
    <DynamicFormAddButton @click="addNotificationThreshold" />
  </div>
</template>
