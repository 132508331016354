<script setup lang="ts">
import type { NotificationThreshold } from '~/types/notification-threshold.type'

const emit = defineEmits<{
  removeNotificationThreshold: []
}>()

const value = defineModel('value', {
  default: 100,
  type: Number as PropType<NotificationThreshold['value']>,
})

const notifyAdmin = defineModel('notifyAdmin', {
  default: false,
  type: Boolean as PropType<NotificationThreshold['notifyAdmin']>,
})

const notifyProjectManager = defineModel('notifyProjectManager', {
  default: true,
  type: Boolean as PropType<NotificationThreshold['notifyProjectManager']>,
})

function toggleNotifyAdmin() {
  notifyAdmin.value = !notifyAdmin.value
}

function toggleNotifyProjectManager() {
  notifyProjectManager.value = !notifyProjectManager.value
}
</script>

<template>
  <div class="flex items-center justify-between space-x-4">
    <NInputNumber v-model:value="value" :min="0" :step="10" placeholder="z. B. 10">
      <template #suffix>
        %
      </template>
    </NInputNumber>
    <NButtonGroup size="small">
      <NButton strong secondary :type="notifyAdmin ? 'primary' : 'default'" round @click="toggleNotifyAdmin">
        Admins
      </NButton>
      <NButton strong secondary :type="notifyProjectManager ? 'primary' : 'default'" round @click="toggleNotifyProjectManager">
        Leads
      </NButton>
    </NButtonGroup>
    <NButtonGroup>
      <DynamicFormRemoveButton @click="emit('removeNotificationThreshold')" />
    </NButtonGroup>
  </div>
</template>
